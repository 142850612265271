export enum EPhoneCode {
  COLOMBIA = '57',
  ENGLAND = '44',
}

export interface IPhoneCode {
  label: string;
  value: EPhoneCode;
}

export const PHONE_CODES: IPhoneCode[] = [
  { label: `🇨🇴 ${EPhoneCode.COLOMBIA}`, value: EPhoneCode.COLOMBIA },
  { label: `🏴󠁧󠁢󠁥󠁮󠁧󠁿󠁧󠁢󠁥󠁮󠁧 ${EPhoneCode.ENGLAND}`, value: EPhoneCode.ENGLAND },
];
