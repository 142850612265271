import { AuthenticationService } from './authentication.service';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as world from 'countries-list';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MainService {
  private keys = ['password', 'device', 'newpassword'];
  public accessToken: string;
  public apiUrl: string;

  constructor(
    public http: HttpClient,
    private authService: AuthenticationService,
  ) {
    this.accessToken = this.authService.getAuthToken();
    this.apiUrl = environment.apiParq;
  }

  async postWithoutLogin(request: string, data: any) {
    for (let key in data)
      if (this.keys.indexOf(key.toLowerCase().trim()) < 0)
        data[key] =
          typeof data[key] == 'string'
            ? data[key].toLowerCase().trim()
            : data[key];

    let response = await this.http
      .post(environment.apiParq + request, data)
      .pipe(map((res) => res))
      .toPromise();

    return response;
  }
  async generateCode(data: any) {
    let email = data.countryCode.slice(1) + data.phone + '@users.parqco.com';
    data.email = email;
    localStorage.setItem('dataForVerification', JSON.stringify(data));
    let request = await this.http
      .post<any>(environment.apiParq + '/profiles/generateCode', {
        countryCode: data.countryCode,
        phone: data.phone,
      })
      .pipe(map((res) => res))
      .toPromise();
  }

  async findProfileByEmail(filter) {
    let response = await this.http
      .get(
        environment.apiParq +
          `/profiles/findOne?filter={"where":{"email":"${filter}"}}`,
      )
      .pipe(map((res) => res))
      .toPromise();
    return response;
  }
  async updateRole(data) {
    const roleObj = {
      role: data.role,
    };
    let response = await this.http
      .post(
        environment.apiParq +
          `/profiles/update?where={"email":"${data.email}"}`,
        roleObj,
      )
      .pipe(map((res) => res))
      .toPromise();
    return response;
  }
  getAllCountries = async () => {
    let countries = { ...world.countries };

    Object.keys(countries).map((key) => {
      countries[key] = {
        dial_code: `+${countries[key].phone}`,
        name: countries[key].name.toLowerCase(),
        code: key.toLowerCase(),
        emoji: countries[key].emoji,
      };
      delete countries[key].phone;
    });
    return Object.values(countries);
  };

  /** @deprecated */
  public async get(
    request: string,
    payload?: Record<string, any>,
    url?: string,
  ): Promise<any> {
    const urlSearchParams = new URLSearchParams();

    if (payload) {
      Object.keys(payload).forEach((key) => {
        if (Array.isArray(payload[key])) {
          payload[key].forEach((value) => {
            urlSearchParams.append(key, value);
          });
        } else {
          urlSearchParams.append(key, payload[key]);
        }
      });
    }

    const apiUrl = url ? url : environment.apiParq;
    const urlParams = payload ? `?${urlSearchParams.toString()}` : '';
    const accessToken = this.authService.getAuthToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    });

    try {
      const response = await this.http
        .get(`${apiUrl}${request}${urlParams}`, { headers })
        .pipe(map((res) => res))
        .toPromise();

      return response;
    } catch (error) {
      return this.handleError(error);
    }
  }

  public async getAdvanced(
    request: string,
    payload: Record<string, any>,
    localUrl?: string,
  ): Promise<any> {
    const urlSearchParams = new URLSearchParams();

    Object.keys(payload).forEach((key) => {
      if (Array.isArray(payload[key])) {
        payload[key].forEach((value) => {
          urlSearchParams.append(key, value);
        });
      } else {
        urlSearchParams.append(key, payload[key]);
      }
    });

    const params = `?${urlSearchParams.toString()}`;
    const accessToken = this.authService.getAuthToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    });

    const baseURL = localUrl ? localUrl : environment.apiParq;

    try {
      const response = await this.http
        .get(`${baseURL}${request}${params}`, { headers })
        .pipe(map((res) => res))
        .toPromise();

      return response;
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getUrl(request: string, access_token: string, data: any) {
    let params = '';
    for (let key in data) {
      params +=
        '&' +
        encodeURIComponent(String(key)) +
        '=' +
        encodeURIComponent(String(data[key]));
    }

    try {
      let result = await this.http
        .get(request + '?access_token=' + access_token + params)
        .pipe(map((res) => res))
        .toPromise();

      return result;
    } catch (error) {
      return this.handleError(error);
    }
  }

  /** @deprecated */
  public async post(
    request: string,
    payload?: Record<string, any>,
    url?: string,
  ): Promise<any> {
    const accessToken = this.authService.getAuthToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    });

    try {
      const apiUrl = url ? url : environment.apiParq;
      const response = await this.http
        .post(`${apiUrl}${request}`, payload, { headers })
        .pipe(map((res) => res))
        .toPromise();

      return response;
    } catch (error) {
      return this.handleError(error);
    }
  }

  async postWithError(
    request: string,
    data: any = {},
    url?: string,
  ): Promise<any> {
    const accessToken = await this.authService.getAuthToken();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    });

    try {
      const apiUrl = url ? url : environment.apiParq;
      const result = await this.http
        .post(`${apiUrl}${request}`, data, { headers })
        .pipe(map((res) => res))
        .toPromise();

      return result;
    } catch (error) {
      return error;
    }
  }

  async uploadCompanyPhoto(
    companyId: number,
    formData: FormData,
  ): Promise<any> {
    try {
      const response: Response = await fetch(
        `${environment.apiParq}/entities/v2/uploads/company?companyId=${companyId}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${this.authService.getAuthToken()}`,
          },
          body: formData,
        },
      );

      return response.json();
    } catch (error) {
      return this.handleError(error);
    }
  }

  public async uploadAvatar(email: string, formData: FormData): Promise<any> {
    try {
      const response: Response = await fetch(
        `${
          environment.apiParq
        }/users/v2/uploads/avatar?email=${encodeURIComponent(email)}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${this.authService.getAuthToken()}`,
          },
          body: formData,
        },
      );

      return response.json();
    } catch (error) {
      console.error(error);
    }
  }

  async patch2(request: string, data: any, localUrl?: string): Promise<any> {
    const accessToken = this.authService.getAuthToken();
    const apiUrl = localUrl ?? environment.apiParq;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    });

    try {
      const result = await this.http
        .patch(`${apiUrl}${request}`, data, { headers })
        .pipe(map((res) => res))
        .toPromise();

      return result;
    } catch (error) {
      return this.handleError(error);
    }
  }

  async patch(
    route: string,
    id: number,
    data: any,
    accessTokenAux: string = null,
  ) {
    const accessToken: string = this.authService.getAuthToken();
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    });

    for (let key in data) {
      if (this.keys.indexOf(key.toLowerCase().trim()) < 0) {
        data[key] =
          typeof data[key] == 'string'
            ? data[key].toLowerCase().trim()
            : data[key];
      }
    }

    try {
      const result = await this.http
        .patch(`${environment.apiParq}${route}/${id}`, data, { headers })
        .pipe(map((res) => res))
        .toPromise();

      return result;
    } catch (error) {
      return this.handleError(error);
    }
  }

  async newPatch(
    request: string,
    id: number,
    payload: Record<string, any>,
    url?: string,
    toLower: boolean = false,
  ) {
    const accessToken = this.authService.getUser().accessToken;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    });

    if (toLower)
      for (let key in payload)
        if (this.keys.indexOf(key.toLowerCase().trim()) < 0)
          payload[key] =
            typeof payload[key] == 'string'
              ? payload[key].toLowerCase().trim()
              : payload[key];

    try {
      const apiUrl = url ? url : environment.apiParq;
      const result = await this.http
        .patch(`${apiUrl}${request}/${id}`, payload, { headers })
        .pipe(map((res) => res))
        .toPromise();

      return result;
    } catch (error) {
      return this.handleError(error);
    }
  }

  async publicPatch(
    url: string,
    id: number,
    payload: Record<string, any>,
    toLower: boolean = false,
  ) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (toLower)
      for (let key in payload)
        if (this.keys.indexOf(key.toLowerCase().trim()) < 0)
          payload[key] =
            typeof payload[key] == 'string'
              ? payload[key].toLowerCase().trim()
              : payload[key];

    try {
      const result = await this.http
        .patch(`${environment.apiParq}${url}/${id}`, payload, {
          headers,
        })
        .pipe(map((res) => res))
        .toPromise();

      return result;
    } catch (error) {
      return this.handleError(error);
    }
  }

  async put(request: string, id: number, data: any) {
    let access_token = await this.authService.getAuthToken();

    for (let key in data)
      if (this.keys.indexOf(key.toLowerCase().trim()) < 0)
        data[key] =
          typeof data[key] == 'string'
            ? data[key].toLowerCase().trim()
            : data[key];

    try {
      let result = await this.http
        .put(
          environment.apiParq +
            request +
            (id ? '/' + id : '') +
            '?access_token=' +
            access_token,
          data,
        )
        .pipe(map((res) => res))
        .toPromise();

      return result;
    } catch (error) {
      return this.handleError(error);
    }
  }

  public async delete(url: string, id: number): Promise<any> {
    const accessToken = this.authService.getUser().accessToken;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    });

    try {
      let result = await this.http
        .delete(`${environment.apiParq}${url}/${id}`, { headers })
        .pipe(map((res) => res))
        .toPromise();

      return result;
    } catch (error) {
      return this.handleError(error);
    }
  }

  private handleError(error: any): any {
    if (error instanceof HttpErrorResponse) {
      console.error(error);
      return this.handleHttpErrorResponse(error);
    }
  }

  private handleHttpErrorResponse(error: HttpErrorResponse): any {
    if (error.status == 400) return undefined;
    if (error.status == 401) return this.authService.logout();
    if (error.status == 404) return undefined;
    if (error.status == 409) return error;
    return error.message;
  }

  getJSON(path: any): Observable<any> {
    return this.http.get(path);
  }

  async getMediaUploadUrl() {
    let access_token = await this.authService.getAuthToken();
    return (
      environment.apiParq +
      '/media/upload?access_token=' +
      'Bearer ' +
      access_token
    );
  }
}
